import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Logger } from "aws-amplify";
import {
  FormLayout,
  TextField,
  Button,
  Layout,
  Card,
  Stack,
  Form,
  DisplayText,
  TextStyle,
  Link,
  Banner,
  InlineError,
} from "@shopify/polaris";
import { useUser } from "hooks/useUser";
import { navigate } from "@reach/router";
import rdmLogo from "images/rdm-logo.png";
import { resendCodeForResetPassword, resetPassword } from "../api/auth";
import { Helmet } from "react-helmet";
import { navigateTo } from "gatsby";

function Login() {
  const logger = new Logger("Forgot-password");

  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState();
  const { user, login, updateUser } = useUser();
  const [isCodeMissing, setIsCodeMissing] = useState(false);
  const [isNewPasswordMissing, setIsNewPasswordMissing] = useState(false);

  if (user) {
    navigate("/dashboard");
    return null;
  }

  useEffect(() => {
    setError("");
    setIsCodeMissing(false);
    setIsNewPasswordMissing(false);
  }, [step]);
  async function handleSendResetCode(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!email) {
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await resendCodeForResetPassword(email);
      setStep(2);
      setIsSubmitting(false);
      console.log(res);
    } catch (err) {
      //FIXME: code duplication
      logger.error(err);
      if (err.code === "ExpiredCodeException") {
        setError("This code is no longer valid, request a new code");
      } else if (err.code === "CodeMismatchException") {
        setError("The code is not valid");
      } else if (err.code === "UserNotFoundException") {
        setError("The user does not exist");
      } else if (err.code === "LimitExceededException") {
        setError("Too many requests, retry later");
      } else if (err.code === "InvalidParameterException") {
        setError("Password must be at least 8 characters long");
      } else {
        Sentry.captureException(err);
      }
      setIsSubmitting(false);
    }
  }

  async function handleChangePassword(e) {
    e.preventDefault();
    if (!code) {
      setIsCodeMissing(true);
    }
    if (!newPassword) {
      setIsNewPasswordMissing(true);
    }
    if (!code || !newPassword) {
      return;
    }
    try {
      setIsSubmitting(true);
      await resetPassword({
        email,
        code,
        newPassword,
      });
      if (!email) {
        navigateTo("/");
      } else {
        await login({ email: email.toLowerCase(), password: newPassword });
        updateUser();
      }
    } catch (err) {
      //FIXME: code duplication
      logger.error(err);
      if (err.code === "ExpiredCodeException") {
        setError("This code is no longer valid, request a new code");
      } else if (err.code === "CodeMismatchException") {
        setError("The code is not valid");
      } else if (err.code === "UserNotFoundException") {
        setError("The user does not exist");
      } else if (err.code === "LimitExceededException") {
        setError("Too many requests, retry later");
      } else if (err.code === "InvalidParameterException") {
        setError("Password must be at least 8 characters long");
      } else {
        Sentry.captureException(err);
      }
      setIsSubmitting(false);
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Reset password | Reserved area</title>
      </Helmet>
      <Container>
        {step === 1 ? (
          <Form onSubmit={handleSendResetCode}>
            <FormLayout>
              <Logo>
                <Image src={rdmLogo} alt="Rue des Mille logo" />
              </Logo>
              <DisplayText size="large">Reset password</DisplayText>
              <TextStyle variation="subdued">
                Insert your email to reset your password
              </TextStyle>
              <TextField
                label="Email"
                onChange={value => {
                  setError("");
                  setEmail(value);
                }}
                value={email}
                type="email"
                autoFocus
              />
              <Stack alignment="baseline">
                <Button primary loading={isSubmitting} submit>
                  Reset
                </Button>
                <Link url="/" monochrome>
                  Login
                </Link>
              </Stack>
              {error && <Banner title={error} status="critical" />}
            </FormLayout>
          </Form>
        ) : (
          <Form onSubmit={handleChangePassword}>
            <FormLayout>
              <Logo>
                <Image src={rdmLogo} alt="Rue des Mille logo" />
              </Logo>
              <DisplayText size="large">Insert the code</DisplayText>
              <TextStyle variation="subdued">
                Check your email and insert the code we have sent you
              </TextStyle>
              <TextField
                label="Codice"
                onChange={value => {
                  setCode(value);
                  setError("");
                  setIsCodeMissing(false);
                }}
                value={code}
                type="text"
                autoFocus
                id="reset-code"
              />
              {isCodeMissing && (
                <InlineError
                  message="Inserisci il codice"
                  fieldID="reset-code"
                />
              )}
              <TextField
                label="New password"
                onChange={value => {
                  setIsNewPasswordMissing(false);
                  setError("");
                  setNewPassword(value);
                }}
                value={newPassword}
                id="new-password"
                type="password"
              />
              {isNewPasswordMissing && (
                <InlineError
                  message="Inserisci la nuova password"
                  fieldID="new-password"
                />
              )}
              <Stack alignment="baseline">
                <Button primary loading={isSubmitting} submit>
                  Log in
                </Button>
                <Button plain onClick={() => setStep(1)}>
                  Request new code
                </Button>
              </Stack>
              {error && <Banner title={error} status="critical" />}
            </FormLayout>
          </Form>
        )}
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .Polaris-FormLayout {
    width: 400px;
    @media (max-width: 500px) {
      margin: 0;
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
    }
  }
`;

const Logo = styled.div`
  width: 200px;
`;

const Image = styled.img`
  width: 100%;
`;

export default Login;
